import styled from 'styled-components';

const FotterWrapper = styled.section`
    padding-top: 40px;
    padding-bottom: 45px;
    overflow: hidden;
    background:  ${props => props.theme.colors.bgfooter};
    position: relative;
    .section__particle{
        position: absolute;
        &.one{
            bottom: -20%;
            right: -80%;
        }
    }
    a:not([href]) {
      color: ${props => props.theme.colors.primaryDark};
    }
    ul{
      padding: 0;
      list-style: none;
      margin: 0; 
    }
    p{
      font-size: 18px;
      line-height: 38px;
      color: ${props => props.theme.colors.headingColor ?  props.theme.colors.headingColor : '#1D316C'};
    }
    .copyright-text{
        margin-top: 30px;
        font-size: 16px;
    }
  a{
    transition: 450ms all; 
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.secondaryColor ?  props.theme.colors.secondaryColor : '#FB7B81'};
    }
  }
  .footer-logo {
    margin-bottom: 15px;
    display: block;
    width: 10rem;
  }
  .footer-widgets {
    &.first {
      margin-top: -10px;
    }
  }
  .subscribe-form {
        position: relative;
        display: flex;
        margin-top: 30px;
        input{
            background: transparent;
            flex-basis: 100%;
            padding: 15px;
            border-radius: 5px;
            border: 1px solid ${props => props.theme.colors.headingColor ?  props.theme.colors.headingColor : '#1D316C'};
        }
        button {
            position: absolute;
            right: 0px;
            top: 0;
            height: 100%;
            min-width: 20px;
            border-radius: 5px;
            background: transparent;
            color: ${props => props.theme.colors.headingColor};
            svg{
                color: ${props => props.theme.colors.headingColor ?  props.theme.colors.headingColor : '#1D316C'};
                font-size: 22px;
                line-height: 100%;
            }
        }
    }
  .footer-widgets {
    .widget-title { 
      font-size: 24px;
      margin-bottom: 35px;
    }
    
    .social{
      li {
        display: inline-block;
        a {
          display: block;
          // height: 30px;
          // width: 30px;
          line-height: 33px;
          //border-radius: 50%;
          //border: 1px solid ${props => props.theme.headingColor ?  props.theme.headingColor : '#1D316C'};
          text-align: center;
          transition: 450ms all;
          &:hover {
            color: ${props => props.theme.colors.secondaryColor ?  props.theme.colors.secondaryColor : '#FB7B81'};  
            border-color: ${props => props.theme.colors.secondaryColor ?  props.theme.colors.secondaryColor : '#FB7B81'};
          }
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }

    
      li + li {
        margin-left: 15px;
      }
    }

    .social-mini{
      display: flex;
      li {
        display: inline-block;
        margin-right: 10px;
        margin-left: 10px;
        a {
          display: block;
          // height: 15px;
          // width: 15px;
          line-height: 18px;
          //border-radius: 50%;
          //border: 1px solid ${props => props.theme.headingColor ?  props.theme.headingColor : '#1D316C'};
          text-align: center;
          transition: 450ms all;
          &:hover {
            color: ${props => props.theme.colors.secondaryColor ?  props.theme.colors.secondaryColor : '#FB7B81'};  
            border-color: ${props => props.theme.colors.secondaryColor ?  props.theme.colors.secondaryColor : '#FB7B81'};
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }  

    .widget-catagory {
      li {
        a {
          font-size: 18px;
          line-height: 22px;
        }
      }
      li + li {
        margin-top: 15px;
      }
    }
  }
  
  @media only screen and (max-width: 1280px) {
    .footer-widgets {
      .social {
        li + li {
          margin-left: 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 912px) {
    .footer-widgets {
      margin-bottom: 70px;
    }
    .section__particle{
        &.one{
            bottom: 0;
            right: -58%;
        }
    }
  }
  @media only screen and (max-width: 568px) {
    padding-top: 10px;
    background-position: 10%;
    text-align: center;
    .footer-widgets {
      margin-bottom:5px;
      align-items: center;
      justify-content: center;
      display: flex;     
      flex-direction: column; 
      .info {
        li {
          justify-content: center;
        }
      }

      p {
        margin-top: 5px;
      }
    }

    .footer-widgets.social-media {
      flex-direction:column;
      margin-top:15px;      
    }

    .footer-logo {
      width: 4rem;
    }    
  }
  @media only screen and (max-width: 375px) {
    padding-top: 10px;
    .footer-widgets {
      margin-bottom:5px;
      &.first {
        margin-top: 20px;
        margin-bottom: 0px;
      }

      p {
        margin-top: 5px;
      }      
    } 

    .footer-widgets.social-media {
      flex-direction:column;
      margin-top:15px;
    }    
    
    .footer-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top:10px;
      width: 4rem;
    }
  }
`;

export default FotterWrapper;
