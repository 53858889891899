import React, { useState } from 'react';
import { Modal as ReactModal }  from 'react-bootstrap';
import TextRich from '@components/TextRich';
import ModalContainer from './modal.style';

interface ModalProps {
    handleClose:any,
    handleShow: any,
    title: string,
    body:any,
    show: boolean
}



const Modal = (props) => {

    //const [show, setShow] = useState(false);

    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true)

  return (
    <ModalContainer>
        <ReactModal onHide={props.handleClose} {...props} centered>
        <ReactModal.Header closeButton>
            <ReactModal.Title>{props.title}</ReactModal.Title>
        </ReactModal.Header>

        <ReactModal.Body style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
            <TextRich json={props.body}/>
        </ReactModal.Body>
        </ReactModal>
    </ModalContainer>
  );
}

export default Modal;