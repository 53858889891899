import styled from 'styled-components';

const ModalContainer = styled.div`
    
    .modal-open .modal{
        z-index:100000000000000000;
    }

    modal-title {
        font-size: 16pt !important;
        font-weight: 700;
        font-family:'Barlow';
        color: ${props => props.theme.colors.primaryDark};
    }

`

export default ModalContainer;